<template>
  <section>
    <loader v-if="loading" />
    <v-card :dark="$dark.get()" min-height="90vh">
      <v-card-title class="header">
        Группы поставщиков
        <v-btn class="coupon-btn" @click="modal = true"
          >Добавить группу</v-btn
        ></v-card-title
      >
      <loader v-if="loading"></loader>
      <v-card-text v-else class="main-content">
        <div v-for="item in GROUPS" :key="item.id" class="main-content_card">
          <p>{{ item.name }}</p>
          <div>
            <v-btn icon color="#5D4BD0" @click="openModal(item)"
              ><v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="deleteGroup(item.id)"
              ><v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- Окно с полной информацией -->
    <v-dialog
      v-model="modal"
      activator="parent"
      :width="$vuetify.breakpoint.mdAndDown ? '95%' : '60%'"
    >
      <v-card class="modal">
        <v-card-title class="modal_title"
          >{{ editMode ? "Изменение" : "Создание" }} группы</v-card-title
        >
        <v-card-text>
          <v-form ref="group" on-submit="return false">
            <v-text-field
              v-model="group.name"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Название группы"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn v-if="editMode" color="#5D4BD0" dark @click="editGroup()"
            >Изменить</v-btn
          >
          <v-btn v-else color="#5D4BD0" dark @click="createGroup()"
            >Создать</v-btn
          >
          <v-btn class="coupon-btn" @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Подтвердение удаления -->
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteGroup()"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import loader from "@/components/Loader";

export default {
  name: "ProviderGroups",
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      group: {
        name: "",
      },
      modal: false,
      editMode: false,
      deletedId: 0,
      show: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      GROUPS: "ProviderGroups/STATE",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.editMode = false;
        this.group = {
          name: "",
        };
        this.$refs.group.resetValidation();
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      GET_ALL_GROUPS: "ProviderGroups/GET_ALL_GROUPS",
      ADD_GROUP: "ProviderGroups/ADD_GROUP",
      DELETE_GROUP: "ProviderGroups/DELETE_GROUP",
      EDIT_GROUP: "ProviderGroups/EDIT_GROUP",
    }),
    //Подгружает все тэги
    async setValues() {
      this.loading = true;
      await this.GET_ALL_GROUPS();
      this.loading = false;
    },
    async createGroup() {
      if (!this.$refs.group.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      const response = await this.ADD_GROUP(this.group);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Группа добавлена", true);
        await this.setValues();
      }
      this.modal = false;
      this.loading = false;
    },
    async editGroup() {
      if (!this.$refs.group.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      const response = await this.EDIT_GROUP(this.group);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Группа изменена", true);
        await this.setValues();
      }
      this.modal = false;
      this.loading = false;
    },
    openModal(group) {
      this.editMode = true;
      this.group = JSON.parse(JSON.stringify(group));
      this.modal = true;
    },
    async deleteGroup(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await this.DELETE_GROUP(this.deletedId);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Группа удалена", true);
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
.main-content {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  &_card {
    display: flex;
    gap: 10px;
    padding: 5px 15px;
    background-color: #eeeeee;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
    width: calc(25% - 20px);
    @media screen and (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }
}
.coupon-btn {
  border: 1px solid #5d4bd0;
  color: #5d4bd0 !important;
  border-radius: 7px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &_bold {
    font-weight: 800;
  }
  &_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
    h1 {
      margin-left: 10%;
    }
  }
}
</style>
